.HeaderPadding {
  padding-top: 0px;
}

.Header {
  top: 0;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);
  position: fixed;
  background-color: white;
  opacity: 0.95;
  z-index: 1;
}

.HeaderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 80vw;
}
.HeaderContainerLeft {
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  flex-direction: row;
  width: 20%;
}
.MenuList {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.MenuLink {
  margin-left: 40px;
  text-decoration: none;
  justify-content: center;
}
.HeaderNumberSix {
  height: 90px;
  color: #c6a76a;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
}
.HeaderNumberSix:hover {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #c6a76a;
  transition: 0.3s;
}

.HamburgerBar {
  display: none;
}
@media only screen and (max-width: 768px) {
  .MenuList {
    display: block;
    position: absolute;
    top: 69px;
    background-color: white;
    width: 100%;
    left: 0;
  }
  .MenuLink {
    display: none;
  }
  .HamburgerBar {
    margin-top: 15px;
    background-color: inherit;
    border: none;
    display: block;
    align-self: flex-start;
  }

  .HamburgerMenu,
  .HamburgerMenuOpen {
    width: 40px;
    height: 30px;
    position: relative;
    margin: 10px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  .HamburgerMenu span,
  .HamburgerMenuOpen span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: black;
    border-radius: 6px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  .HamburgerMenu span:nth-child(1) {
    top: 0px;
  }

  .HamburgerMenu span:nth-child(2),
  .HamburgerMenu span:nth-child(3),
  .HamburgerMenuOpen span:nth-child(2),
  .HamburgerMenuOpen span:nth-child(3) {
    top: 12px;
  }

  .HamburgerMenu span:nth-child(4),
  .HamburgerMenuOpen span:nth-child(4) {
    top: 24px;
  }

  .HamburgerMenuOpen span:nth-child(1) {
    top: 12px;
    width: 0%;
    left: 50%;
  }

  .HamburgerMenuOpen span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .HamburgerMenuOpen span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .HamburgerMenuOpen span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

.ReturnButton {
  width: 65px;
  height: 90px;
  color: #c6a76a;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  transition: 0.3s;
  font-size: 20px;
}

.YearButton {
  width: 65px;
  height: 90px;
  color: #c6a76a;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  transition: 0.3s;
  font-size: 20px;
}
.ReturnButtonSticky {
  top: 0px;
  flex-direction: row;
  display: flex;
  width: 600px;
  justify-content: space-evenly;
}
.ReturnButton:hover {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #c6a76a;
  transition: 0.3s;
}
.YearButton:hover {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #c6a76a;
  transition: 0.3s;
}
@media only screen and (max-width: 1440px) {
  .YearButton {
    display: none;
  }
  .HeaderContainerLeft {
    align-items: flex-start;
    display: flex;
    width: 40%;
  }
  .ReturnButtonSticky {
    width: 10px;
  }
  .HeaderContainer {
    width: 95vw;
  }
}
