.Boxed {
  border-radius: 0px;
  width: 300px;
  height: 90px;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #c6a76a;
  text-transform: uppercase;
  transition: 0.3s;
  font-size: 20px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: white;
  margin-left: 40px;
}
.Boxed:hover {
  transition: 0.3s;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #c6a76a;
}
.text {
  text-decoration-line: none;
}
.text:hover {
  text-decoration-line: none;
}

@media only screen and (max-width: 1920px) {
  .LongerTextForBigScreens {
    display: none;
  }
}
