.infoMain {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.infoContainer {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.infoImgContainer {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.image {
  width: 95%;
  height: auto;
}
.infoTextContainer {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.textAlignBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
}
.infoTitle {
  color: #c3aa77;
}
.infoTextBlock {
  text-align: justify;
  width: 80%;
  color: #696969;
  line-height: 175%;
}
h2 {
  font-size: 3.5em;
}

@media only screen and (max-width: 580px) {
  .infoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
  }
  .infoTextContainer {
    width: 100%;
  }
  .infoImgContainer {
    width: 100%;
    margin-top: 30px;
  }
  .infoTextContainer {
    justify-content: center;
  }
  .textAlignBox {
    align-items: center;
  }
}
