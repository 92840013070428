.NewsSiteContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.NewsContentWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
}
.DateWrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 70px;
  width: 230px;
}

.News {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  margin-top: 70px;
  padding: 2px;
  padding-left: 15px;
  height: 100%;
  border-color: rgba(0, 0, 0, 0.1);
  border-left-style: solid;
  border-left-width: 1px;
}
.NewsHeadline {
  margin-bottom: 30px;
  height: 35px;
  font-size: 20px;
  text-align-last: left;
  word-wrap: break-word;
  text-align: start;
  font-weight: bolder;
  color: #696969;
}
.NewsHeadlineDate {
  font-size: 12px;
  text-align: justify;
  text-align-last: left;
  word-wrap: break-word;
  color: #696969;
  margin-bottom: 10px;
  display: none;
}
.NewsDate {
  color: #696969;
  font-size: 25px;
}
.NewsIndex {
  font-size: 14px;
  color: #696969;
  text-align: justify;
  text-align-last: left;
  word-wrap: break-word;
  display: inline-block;
}

.NewsTitle {
  padding-top: 30px;
  color: #c3aa77;
  width: 80%;
  display: flex;
}
.TotalWrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
}

.NewsContainer {
  background-color: white;
  height: auto;
  width: 60%;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.Line {
  border-color: rgba(0, 0, 0, 0.1);
  border-left-style: solid;
  border-left-width: 1px;
  margin: 30px;
  height: inherit;
  width: 30px;
}

@media only screen and (max-width: 768px) {
  .News {
    width: 80%;
    border: none;
    padding-left: 2px;
  }
  .DateWrapper {
    display: none;
  }
  .NewsHeadlineDate {
    display: block;
  }
  .NewsContainer {
    width: 100%;
  }
}
