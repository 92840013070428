@import url('https://fonts.googleapis.com/css?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  background-color: #f4f7f6;
}

.AppContent {
  margin-top: 90px;
}
