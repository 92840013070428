.ChallongeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 50px;
  font-size: 12px;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.tableWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.groupWrapper {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.singleTableWrap {
  width: 45%;
}
.allMatchesWrapper {
  width: 92.5%;
}
.currentTitle {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 400;
  text-align: start;
  width: 95%;
}
.currentMatch {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.currentWrapper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.nextWrapper {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.currentContent {
  width: 25%;
  font-size: 50px;
  font-weight: 200;
  color: #696969;
}
.nextContent {
  width: 25%;
  font-size: 30px;
  font-weight: 200;
  color: #696969;
}
.front {
  text-align: right;
}
.back {
  text-align: left;
}
.divider {
  width: 10%;
  font-size: 50px;
  font-weight: 200;
  color: #696969;
}

.ChallongeTitle {
  display: flex;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 400;
  width: 95%;
  margin-bottom: 20px;
  color: #c6a76a;
}
.nextMatchTitle {
  display: flex;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
  width: 95%;
  margin-bottom: 20px;
  color: #c6a76a;
}

@media only screen and (max-width: 768px) {
  .ChallongeContainer {
    justify-content: center;
    align-items: center;
  }
  .groupWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .singleTableWrap {
    width: 350px;
  }
  .allMatchesWrapper {
    width: 350px;
  }
  .currentContent {
    font-size: 35px;
  }
  .nextContent {
    font-size: 20px;
  }
  table {
    font-size: 9px;
    margin-bottom: 20px;
  }
}
