.CompetitionView {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopBoxContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.CompetitionViewWrapper {
  margin-top: 50px;
}

.TopBoxImageContainer {
  justify-content: center;
  width: 40%;
}

.TopBoxImage {
  width: 100%;
}

.TopBoxTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.TopBoxWinnerDonation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.TopBoxText {
  max-width: 100%;
  width: 80%;
  margin-bottom: 25px;
  margin-top: 30px;
}
.ParticipantsName {
  width: 25%;
  color: #696969;
  margin-bottom: 15px;
  text-align: start;
}
.TopBoxTextWinner {
  width: 100%;
  font-size: 46px;
  color: #c6a76a;
}
.TopBoxTextDonation {
  font-size: 40px;
  color: #c6a76a;
}
.TopBoxTextHeader {
  font-weight: bold;
  font-size: 25px;
  color: #696969;
  margin-bottom: 5px;
}
.ParticipantsBox {
  max-width: 100%;
  display: flex;
  align-self: center;
  justify-content: center;
  margin-bottom: 100px;
}
.MiddleBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.BottomBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}
.BottomBoxOrganization {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
}
.TopBoxParticipantsHeader {
  font-weight: bold;
  border-bottom-width: 1px;
  border-bottom-color: #696969;
  border-bottom-style: solid;
  font-size: 25px;
  color: #696969;
  padding-bottom: 20px;
  margin-bottom: 25px;
}
.TopBoxParticipantsText {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.TopBoxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.AboutTournamentBox {
  text-align: justify;
  text-align-last: left;
  word-wrap: break-word;
  width: 55%;
  line-height: 2;
}
h3 {
  color: #c6a76a;
}
.BottomBoxSponsor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.BottomBoxSponsorText {
  color: black;
  font-size: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.BottomBoxSponsorImage {
  width: 12.5%;
  height: auto;
  justify-content: center;
  display: flex;
  align-items: center;
}
.AboutBox {
  text-align: justify;
  text-align-last: left;
  word-wrap: break-word;
  display: inline-block;
  width: 70%;
  line-height: 2;
}
.OrganizationPicture {
  width: 15%;
  height: auto;
  margin: 2%;
}

.ImageCarousel {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  max-width: 825px;
}
.ParticipantButton {
  display: none;
  height: 50px;
  width: 100%;
  background-color: #c6a76a;
  text-align: center;
}
.CompetitionListContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.ListWrapper {
  width: 80%;
  margin-top: 60px;
}
.CompetitionListTile {
  margin-top: 40px;
  font-size: calc(2em + 1vw);
  color: #c6a76a;
}

@media only screen and (max-width: 768px) {
  .ParticipantsName {
    width: 225px;
  }
  .ImageCarousel {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .TopBoxContainer {
    width: 100%;
  }

  .ReturnButton {
    top: 4%;
  }
  .ReturnButtonSticky {
    top: 4%;
    width: 90%;
  }
  .TopBoxTextWinner {
    font-size: 30px;
  }
  .TopBoxTextDonation {
    font-size: 30px;
  }
  .TopBoxText {
    width: 100%;
  }

  .ReturnButtonSticky a {
    display: none;
  }
  .CompetitionViewWrapper {
    margin-top: 20px;
  }
  .ParticipantsBox {
    margin-bottom: 30px;
  }

  .BottomBox {
    width: 100%;
  }

  .ReturnButtonSticky a:first-child {
    display: block;
  }

  .TopBoxImage {
    justify-content: center;
  }
  .TopBoxParticipantsText {
    height: 250px;
    overflow: hidden;
    position: relative;
  }

  .ParticipantButton {
    margin-bottom: 40px;
    font-size: 24px;
    display: block;
    padding: 3%;
    color: white;
  }
  .TopBoxTextHeader {
    font-size: 20px;
  }
  .YearButton {
    display: none;
  }
  .BottomBoxSponsorImage {
    width: 50%;
  }
  .BottomBoxSponsor {
    margin-top: 7%;
  }
  .TopBoxWrapper {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  }

  .BottomBoxOrganization {
    flex-direction: column;
    width: 90%;
  }
  .AboutBox {
    width: 100%;
  }
  .AboutTournamentBox {
    width: 100%;
  }
  .TopBoxImageContainer {
    margin-top: 20px;
    width: 100%;
  }
  .OrganizationPicture {
    width: 50%;
    padding: 1px;
  }
  h1 {
    transform: scale(0.7);
    width: 90%;
  }
  .CompetitionViewWrapper {
    width: 90%;
  }
}

a:hover,
a:active,
a:visited,
a:link {
  text-decoration: none;
}
h1 {
  color: #c6a76a;
}
