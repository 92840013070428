.LiveContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.InfoHeader {
  background-color: #f8f8f8;
  width: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TitleText {
  font-size: 50px;
  font-weight: 600;
  color: #c6a76a;
  margin: 20px;
}
.SubTitle {
  color: #696969;
  font-size: 30px;
  font-weight: 300;
}
.Line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  height: 20px;
  width: 90%;
  margin-bottom: 30px;
}
.DonateButton {
  height: 50px;
  width: 350px;
  font-size: 30px;
  padding-top: 3px;
  text-align: center;
  color: white;
  cursor: pointer;
  background-color: #c6a76a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
}

.LiveInfoWrapper {
  display: flex;
  flex-direction: row;
  width: inherit;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 40px;
  padding-bottom: 10px;
}
.LiveTitleWrapper {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.DonateButton:hover {
  transform: scale(1.03);
}

.ChallongeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  height: 90%;
}

.TwitterContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 30%;
}
.logoContainer {
  width: 100%;
}
.legerUtenGrenserContainer {
  width: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}
.donationTitle {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 400;
  color: #c6a76a;
}
.kiwiContainer {
  width: 90%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.LiveTitleWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.LiveCircle {
  margin-top: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  align-self: flex-start;
  background-color: #c6a76a;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.43);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .LiveInfoWrapper {
    flex-direction: column;
  }
  .ChallongeWrapper {
    width: 100%;
  }

  .TwitterContainer {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .logoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mainTwitterContainer {
    width: 90%;
  }
}
