* {
  box-sizing: border-box;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #111;
  font-size: calc(1em + 1vw);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.finished {
  visibility: hidden;
  color: #696969;
  font-size: 20px;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.finished.data-loaded {
  visibility: visible;
}
.finishedTitle {
  font-weight: 400;
  font-size: 40px;
  color: #696969;
}
.finishedMainContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.liveButton {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 50px;
  background-color: #c6a76a;
  color: white !important;
  justify-self: center;
  font-weight: 500;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 768px) {
  .finishedTitle {
    font-size: 30px;
    width: 80%;
  }
  .liveButton {
    width: 60%;
  }
}
