.FooterContainer {
  margin-top: 40px;
  width: 100%;
  height: 250px;
  background-color: #1f1f1f;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  padding-top: 30px;
  padding-bottom: 10px;
}
.FooterTitle {
  color: white;
  font-weight: 400;
  font-size: 20px;
  margin-bottom: 20px;
}
.FooterContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 10px;
}
.LeftWrapper {
  display: flex;
  flex-direction: row;
  width: 33%;
}
.LinkWrapper {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.FooterLink {
  color: #696969;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 5px;
}
.FooterLink:hover {
  color: #c3aa77;
}
.FooterLogo {
  width: 33%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  text-align: center;
}
img {
  display: block;
  transform-origin: 100%;
}

.FooterContentTitle {
  color: white;
  font-weight: 200;
  font-size: 16px;
  margin-bottom: 10px;
}
.socialMediaContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.listTitle {
  font-weight: 600;
  color: #696969;
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 5px;
}

.OrgInfo {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 33%;
}
.OrgTitle {
  color: white;
}
.OrgInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.OrgInfoContent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.tradeMark {
  color: #696969;
  font-size: 14px;
}

@media only screen and (max-width: 768px) {
  .FooterContainer {
    flex-direction: column;
    justify-content: center;
    height: 560px;
  }
  .FooterContent {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .LeftWrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
  .LinkWrapper {
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 0;
  }
  .socialMediaContent {
    align-items: center;
  }
  .FooterLogo {
    margin-left: 0;
    width: 90%;
  }
  .OrgInfo {
    margin-top: 20px;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .OrgInfoContainer {
    align-items: center;
  }
  .OrgInfoContent {
    align-items: center;
  }
  .address {
    height: 20px;
    width: 300px;
  }
}
.footer {
  background-color: white;
}
